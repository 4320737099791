import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Typography,
  DatePicker,
  Spin,
  Checkbox
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import ConstantsDate from '../../../constants/ConstantsDate';
import './styles/styles.css';

import { defaultRequiredRules} from '../../../utils/Utils';

import {useCreateCollectionRequestApiMutation, useGetFiltersCollectionRequestsApiQuery, useLazyGetBanksApiQuery } from '../../../services/collectionRequests';

const { Text } = Typography;
const { Option } = Select;

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={i[value]} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function NewCollectionRequestModal(props) {
  const [chain, setChain] = useState('');
  const [company, setCompany] = useState('');
  const [agency, setAgency] = useState('');

  const { t } = useTranslation();
  const loading = useSelector((state) => state.reducerLoading);

  const {
    data: filters
  } = useGetFiltersCollectionRequestsApiQuery();

  const [
    createCollectionRequest,
    {
      data: createCollectionRequestResult,
      isLoading: isLoadingCreateCollectionRequest,
      isSuccess: isSuccessCreateCollectionRequest,
      error: errorCreateCollectionRequest,
      reset: resetCreateCollectionRequest
    }
  ] = useCreateCollectionRequestApiMutation({
    fixedCacheKey: 'shared-create-collection-request'
  });

  const [
    getBankInfo,
    {
      data: bankInfo,
      error: errorBankInfo
    }
  ] = useLazyGetBanksApiQuery();

  const {
    handleOk,
    handleCancel,
    visible
  } = props;
  const { TextArea } = Input;

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };

  useEffect(() => {
    if (errorCreateCollectionRequest) {
      message.error(errorCreateCollectionRequest.message);
    } else if (isSuccessCreateCollectionRequest) {
      message.success(createCollectionRequestResult.message);
      resetCreateCollectionRequest();
      handleOk();
    }
  }, [errorCreateCollectionRequest, isSuccessCreateCollectionRequest, createCollectionRequestResult, handleOk]);

  useEffect(() => {
    if (agency && chain) {
      getBankInfo({
        chain, agency
      });
    }
  }, [agency, chain, getBankInfo]);

  useEffect(() => {
    if (errorBankInfo) {
      message.error(errorBankInfo.message);
    }
    if (bankInfo && bankInfo?.length) {
      form.setFieldValue('bank', bankInfo[0]?.banco);
      form.setFieldValue('bank_address', bankInfo[0]?.direccion);
      form.setFieldValue('bank_account_number', bankInfo[0]?.numero_cuenta_bancaria);
      form.setFieldValue('swift', bankInfo[0]?.swift);
      form.setFieldValue('bank_account_owner', bankInfo[0]?.titular);
    }
  }, [bankInfo, errorBankInfo, form]);

  useEffect(() => {
    if (agency) {
      const currentAgency = filters?.agencies?.find((item) => item.id === agency);
      form.setFieldValue('country', currentAgency?.pais);
      form.setFieldValue('agencyEmail', currentAgency?.email);
    }
  }, [agency, form]);

  const onFinishForm = () => {
    console.log('form', form.getFieldValue('send_email_to_agency'));
    createCollectionRequest({
      date: form.getFieldValue('date').format('YYYY-MM-DD'),
      chain,
      company,
      agency,
      amount: form.getFieldValue('amount'),
      currency: form.getFieldValue('currency'),
      description: form.getFieldValue('description') ?? undefined,
      emailCompany: form.getFieldValue('email_company_cc'),
      sendEmailToAgency: form.getFieldValue('send_email_to_agency'),
      agencyEmail: form.getFieldValue('agencyEmail'),
    });
  };

  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const companiesOptions = getOptionsForSelect(filters?.companies.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');
  const currencyOptions = getOptionsForSelect(filters?.currencies, 'text-option-value', 'currency');
  const agenciesOptions = getOptionsForSelect(filters?.agencies, 'text-option-value', 'id', 'nombre');

  const formInitialValues = {
    date: moment(),
    currency: filters.currencies?.length ? filters.currencies[0]?.currency : null
  };

  return (
    <Spin spinning={isLoadingCreateCollectionRequest} size="large">
      <Modal
        title={t('text_new_collection_request')}
        open={visible}
        onOk={onOk}
        confirmLoading={loading}
        onCancel={() => {
          handleCancel();
        }}
        width={800}
        okText={t('text_create_collection_request')}
        closeIcon={<CloseCircleOutlined className="icon-collection-request" />}
      >
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={onFinishForm}
          initialValues={formInitialValues}
        >
          <div className="container-modal">
            <div>
              {/* date */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="date"
                    label={
                      <Text className="text-title-description">{t('text_date')}</Text>
                }
                  >
                    <DatePicker
                      className="input-collection-request-modal"
                      format={ConstantsDate.DATE_FORMAT_PICKER}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* cadena y empresa */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="chain"
                    label={
                      <Text className="text-title-description">{t('text_chain')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Select
                      className="select-input"
                      allowClear
                      showSearch
                      onChange={(value) => {
                        setChain(value);
                      }}
                      value={chain}
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                    >
                      {chainsOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="company"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('company')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Select
                      className="select-input"
                      allowClear
                      showSearch
                      onChange={(value) => {
                        setCompany(value);
                        form.setFieldValue('nit', filters?.companies?.find((item) => item.value === value.toString())?.nit);
                      }}
                      value={company}
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                    >
                      {companiesOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* nit */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="nit"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('nit')}</Text>
                }
                  >
                    <Input
                      disabled
                      className="input-collection-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* email company cc */}
              {/*
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="email_company_cc"
                    rules={defaultRequiredRules(t('required_field'))}
                    label={
                      <Text className="text-title-description">{t('email_company_cc')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>
              */}
            </div>
            <div>
              {/* collection to manage fields */}
              <Row className="mb-3" gutter={2} justify="start">
                <Col span={6}>
                  <div className="justify-flex-end modal-section-name-text">
                    Cobro a gestionar:
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="agency"
                    label={
                      <Text className="text-title-description">{t('agency')}</Text>
                }
                    rules={defaultRequiredRules(t('required_field'))}
                  >
                    <Select
                      className="select-input"
                      allowClear
                      showSearch
                      onChange={(value) => {
                        setAgency(value);
                      }}
                      value={agency}
                      filterOption={(input, option) => option?.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
                    >
                      {agenciesOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="country"
                    label={
                      <Text className="text-title-description">{t('country')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="agencyEmail"
                    label={
                      <Text className="text-title-description">{t('agency_email')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col
                  span={24}
                >
                  <Form.Item
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('text_import')}</Text>
                }
                  >
                    <Input.Group compact>
                      <Form.Item
                        className="mb-1"
                        name="amount"
                        noStyle
                        rules={defaultRequiredRules(t('requiredAmount'))}
                      >
                        <InputNumber
                          placeholder={t('text_import')}
                          className="input-group-collection-request-modal"
                          decimalSeparator=","
                          stringMode
                        />

                      </Form.Item>
                      <Form.Item
                        className="mb-1"
                        name="currency"
                        noStyle
                        rules={defaultRequiredRules(t('required_field'))}
                      >
                        <Select
                          className="select-group"
                          placeholder={t('text_currency')}
                        >
                          {currencyOptions}
                        </Select>
                      </Form.Item>

                    </Input.Group>
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label={
                      <Text className="text-title-description">{t('text_description')}</Text>
                }
                  >
                    <TextArea
                      row={2}
                      placeholder={t('text_description')}
                      className="input-collection-request-modal"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div>
              {/* bank info  */}
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bank_account_owner"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('bank_account_owner')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="bank"
                    className="mb-1"
                    label={
                      <Text className="text-title-description">{t('text_bank')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bank_address"
                    label={
                      <Text className="text-title-description">{t('bank_address')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    className="mb-1"
                    name="bank_account_number"
                    label={
                      <Text className="text-title-description">{t('bank_account_number')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    name="swift"
                    label={
                      <Text className="text-title-description">{t('swift')}</Text>
                }
                  >
                    <Input
                      className="input-collection-request-modal"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={18} offset={6}>
                  <Form.Item
                    name="send_email_to_agency"
                    valuePropName="checked"
                  >
                    <Checkbox>
                      Enviar email a la agencia
                    </Checkbox>
                  </Form.Item>

                </Col>
              </Row>

            </div>

          </div>
        </Form>
      </Modal>
    </Spin>
  );
}

NewCollectionRequestModal.defaultProps = {
  visible: false,
  handleOk: undefined,
  handleCancel: undefined,
};

NewCollectionRequestModal.propTypes = {
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
